body{ 
  position: fixed !important;
}
.global_container{
  width: 100vw;
  height: 90vh;
  margin-top: 0vh;
  background-color: #F0F0F3;
  border-radius: 30px 30px 0px 0px;
}
.global_container_dark{ 
  width: 100vw;
  height: 90vh;
  margin-top: 0vh;
  background-color: #1E2834;
  border-radius: 30px 30px 0px 0px;

}
.global_content_title{
  font-size: 26px;
  color:#585858;
}
.global_circle_btns{
width: 55px;
height: 55px;
border-radius: 300px;
border: 1px solid #585858;
box-shadow: 10px 10px 30px rgba(174, 174, 192, 0.4), -10px -10px 30px #FFFFFF;
}
.global_circle_btns_dark{
  width: 55px;
  height: 55px;
  border-radius: 300px;
  background: #1E2834;
  border: 1.13105px solid #EA4335;
  box-sizing: border-box;
  box-shadow: 11.3105px 11.3105px 33.9316px rgba(0, 0, 0, 0.6), -4.52422px -4.52422px 18.0969px rgba(255, 255, 255, 0.12);
  }


input{
border: 1px solid red;
border-radius: 5px;
height: 30px;
padding-left: 10px;
}
.box-shadow{
  box-shadow: 10px 10px 30px rgba(174, 174, 192, 0.4), -10px -10px 30px #FFFFFF;
}
label{
  margin-bottom: 2px;
  color: #FF5759;
}
.conditions{ 
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
  overflow-y: scroll;
  width: 80%;
  height: 80%;
}
.TermsCondition{
  position: absolute;
  margin-top: -6vh;
}
.paymentButton{
    /* width: 100%;  */
    padding: 14px;
    border: 1px solid black;
    border-radius: 60px;
    margin-top: 9%;
    box-shadow: 10px 10px 30px rgba(174, 174, 192, 0.4), -10px -10px 30px #FFFFFF;
}
.paymentButton_dark{
    /* width: 100%;  */
    padding: 14px;
    border-radius: 7px;
    margin-top: 9%;
    background: #1E2834;
    border: 1px solid #EA4335;
    box-sizing: border-box;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.6), -10px -10px 30px rgba(255, 255, 255, 0.12);
    border-radius: 60px;
}

.paymentSelectionIcons{
    width: 17px;
    height: 17px;
    margin-right: 7px;
    transform: translateY(2px);
}
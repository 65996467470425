/* Header */
#header{
    width: 100%;
    height: 10vh;
    overflow-x: hidden;
    display: flex;
}
#logo_img{
    width:50%;
    margin-top: 10px;
}
#hamburger_container{
    width: 35%;
}
.header_containers{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hamburger_menu{
    width: 100%;
    height: 2px;
    background-color: #585858;
    margin: 3px 0px 0px 0px;
}
.hamburger_menu_dark{
    width: 100%;
    height: 2px;
    margin: 3px 0px 0px 0px;
    background-color: white;
}
.back_button{ 
    border: 1px solid #585858;
    color: #585858;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    top:7px;
    left: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;

}
/* width & height was 155px */
#timeInfo{
    width: 185px; 
    height: 185px;
    background: #E5E5EA;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
#timeInfo_dark{
    width: 155px;
    height: 155px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #121921;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
}
.timer_holder{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #585858;
    margin-bottom: 0px;
}
.timer_holder #time_holder p{
    font-weight: bold;
}
#time_holder{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#time{
    text-align: center;
    margin: 1px 0px 3px 0px;
    font-weight: 400 !important;
}
.bar{
    transform: rotate(rotate(-160 100 100));
}

#cost_amount{ 
    width: 50%;
    text-align: center;
    margin: 2rem 0 2rem 0;
    color: #585858;
    font-weight: bold;
}
#cost_amount_dark{ 
    width: 50%;
    text-align: center;
    margin: 2rem 0 2rem 0;
    color: white;
    font-weight: bold;
}
.cost_holder{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -6%;
}
.price{
    color: red;
    height: min-content;
}
#sun{
    width: 20px;
    height: 20px;
    margin-top: -10px;
    }
#moon{
        width: 17px;
        height: 17px;
        margin-left: 10px;
        margin-top: -10px;
    }
    .daily_container{
        display: flex;
        align-items: center;
    }
    .rate_container{
    display: flex;
    }
p.rate_{
    font-size: 14px;
    margin-left: 2px;
    margin-top: 0px;
}
.set_time{
text-align: center ;
margin-bottom: 0px;
margin-top: 0px;
color: rgb(255, 79, 79);
}
.set_time_button{
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
width: 90%;
border-radius: 7px;
border: 1px solid rgb(255, 79, 79);;
font-size: 10px;
padding-top: 3px;
padding-bottom: 3px;
color: rgb(255, 79, 79);
font-weight: bold;
margin-top: -5px !important;

}
.day_rate{
display: flex; 
justify-content: center;
margin-top: 14%;
}
.night_rate{
    display: flex; 
    justify-content: center;
    margin-top: 2px;
    }
#hours_{
    margin-left: 5px;
    font-weight: bold;
}
#cost_{
margin-left: 10px;
color: rgb(255, 79, 79);
}
.dayRate_icon{
display: flex;
align-items: center;
padding-top: 4.5%;
}
.nightRate_icon{
    display: flex;
    align-items: center;
    padding-top: 4.5%;
}
.nightRate_icon img{
    margin-left: -4.5% !important;
}
.Send_receipt_copy, .Send_email_copy{
    width: 40%;
    margin-right: 15px;
    border: 1px solid gray;
    padding: 10px 0 10px 0;
    border-radius: 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: none;
}
.exit_container{ 
    width: 100%; 
    display: flex;
    justify-content: flex-end;
}
.close_receipt_btn{ 
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}